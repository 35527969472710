import React from "react"
import styled from "styled-components"

const PageStyles = styled.div`
  background: lightgray;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5) inset;
  width: 100%;
  height: 100%;
  overflow: hidden;
  a {
    display: block;
    position: absolute;
    width: 80%;
    max-width: 100px;
    height: 100px;
    /* background: red; */
    top: ${(props) => (props.top ? `${props.top}%` : "-100%")};
    left: ${(props) => (props.left ? `${props.left}%` : "-100%")};
    transform: translate(-50%, -50%);
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .page-shadow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.6) inset;
    pointer-events: none;
  }
`

export default function Page({ href, image, top, left, mobile }) {
  return (
    <PageStyles
      className={mobile ? "page-mobile" : "page"}
      top={top}
      left={left}
    >
      <img src={image} alt="" />
      <a href={href} target="_blank" rel="noreferrer" />
      <div className="page-shadow" />
    </PageStyles>
  )
}
