import React from "react"
import { PageFlip } from "page-flip"
import styled from "styled-components"
import { useMediaQuery } from "@react-hook/media-query"
import Page from "./Page"
import { imagePromise } from "../../utils/imagePromise"

import page1 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_01.jpg"
import page2 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_02.jpg"
import page3 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_03.jpg"
import page4 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_04.jpg"
import page5 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_05.jpg"
import page6 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_06.jpg"
import page7 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_07.jpg"
import page8 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_08.jpg"
import page9 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_09.jpg"
import page10 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_10.jpg"
import page11 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_11.jpg"
import page12 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_12.jpg"
import page13 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_13.jpg"
import page14 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_14.jpg"
import page15 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_15.jpg"
import page16 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_16.jpg"
import page17 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_17.jpg"
import page18 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_18.jpg"
import page19 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_19.jpg"
import page20 from "../../assets/pdf-pages/PEN_Xmas2021_GiftGuide_Page_20.jpg"

import page1Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_01.jpg"
import page2Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_02.jpg"
import page3Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_03.jpg"
import page4Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_04.jpg"
import page5Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_05.jpg"
import page6Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_06.jpg"
import page7Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_07.jpg"
import page8Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_08.jpg"
import page9Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_09.jpg"
import page10Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_10.jpg"
import page11Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_11.jpg"
import page12Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_12.jpg"
import page13Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_13.jpg"
import page14Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_14.jpg"
import page15Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_15.jpg"
import page16Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_16.jpg"
import page17Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_17.jpg"
import page18Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_18.jpg"
import page19Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_19.jpg"
import page20Mobile from "../../assets/pdf-pages-mobile/PEN_Xmas2021_GiftGuide_MOBILE_Page_20.jpg"

const BookContainer = styled.section`
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 25px;
  }
  .button-container {
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    padding: 30px 0 10px;
    margin-top: 20px;
    z-index: 3;
    button {
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      color: black;
      z-index: 2;
      text-transform: uppercase;
      font-size: 1.1rem;
      font-family: "Montserrat Light", sans-serif;
      margin-bottom: 0;
    }
    #previous-page,
    #previous-page-sml {
      @media (max-width: 693px) {
        display: none;
      }
    }
    #previous-page-mobile,
    #previous-page-mobile-sml {
      display: none;
      @media (max-width: 693px) {
        display: block;
      }
    }
  }
`

const DownloadButton = styled.a`
  cursor: pointer;
  color: black;
  z-index: 25;
  text-transform: uppercase;
  font-size: 11px;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-top: -48px;
  width: 100%;
  max-width: 230px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #000;
  letter-spacing: 1.2px;
  color: #000;
  &:hover {
    background-color: black;
    color: white;
  }
  @media (max-width: 600px) {
    margin-top: 10px;
  }
`

export default function BookletDesktop() {
  const [loading, setLoading] = React.useState(true)
  const matches = useMediaQuery("only screen and (max-width: 768px)")

  function initPageFlip() {
    const pageFlip = new PageFlip(document.getElementById("book"), {
      width: 210,
      height: 297,
      disableFlipByClick: true,
      showCover: true,
      maxWidth: 550,
      minWidth: 297,
      maxHeight: 777.84,
      minHeight: 420,
      size: "stretch",
    })

    pageFlip.loadFromHTML(document.querySelectorAll(".page"))

    document.getElementById("previous-page").addEventListener("click", () => {
      pageFlip.flipPrev()
    })

    document
      .getElementById("previous-page-mobile")
      .addEventListener("click", () => {
        pageFlip.turnToPrevPage()
      })

    document.getElementById("next-page").addEventListener("click", () => {
      pageFlip.flipNext()
    })
  }

  React.useEffect(() => {
    imagePromise(setLoading)
  }, [])

  React.useEffect(() => {
    !loading && initPageFlip()
  }, [loading])

  return (
    <>
      <BookContainer>
        {loading && <div>Loading...</div>}
        <div id="book" style={{ opacity: loading ? 0 : 1 }}>
          <Page image={matches ? page1Mobile : page1} />
          <Page image={matches ? page2Mobile : page2} />
          <Page image={matches ? page3Mobile : page3} />
          <Page
            image={matches ? page4Mobile : page4}
            href="https://www.penhaligons.com/uk/en/product/a-fragrant-countdown-000000000065174033"
            top={70}
            left={50}
          />
          <Page image={matches ? page5Mobile : page5} />
          <Page
            image={matches ? page6Mobile : page6}
            href="https://www.penhaligons.com/uk/en/categories/CHRISTMAS/shop-all/halfetis-rose-dreams-000000000065174045"
            top={55}
            left={50}
          />
          <Page image={matches ? page7Mobile : page7} />
          <Page
            image={matches ? page8Mobile : page8}
            href="https://www.penhaligons.com/uk/en/categories/CHRISTMAS/shop-all/hearts-and-humbugs-discovery-collection-000000000065177289"
            top={90}
            left={35}
          />
          <Page
            image={matches ? page9Mobile : page9}
            href="https://www.penhaligons.com/uk/en/categories/CHRISTMAS/shop-all/jolly-mixture-000000000065174030"
            top={45}
            left={30}
          />
          <Page
            image={matches ? page10Mobile : page10}
            href="https://www.penhaligons.com/uk/en/product/dolly-mixture-000000000065174032"
            top={85}
            left={64}
          />
          <Page
            image={matches ? page11Mobile : page11}
            href="https://www.penhaligons.com/uk/en/categories/CHRISTMAS/shop-all/the-chimes-of-christmas-000000000065174037"
            top={52}
            left={28}
          />
          <Page
            image={matches ? page12Mobile : page12}
            href="https://www.penhaligons.com/uk/en/categories/CHRISTMAS/shop-all/the-nose-twitchers-000000000065174039"
            top={65}
            left={50}
          />
          <Page image={matches ? page13Mobile : page13} />
          <Page image={matches ? page14Mobile : page14} />
          <Page image={matches ? page15Mobile : page15} />
          <Page image={matches ? page16Mobile : page16} />
          <Page image={matches ? page17Mobile : page17} />
          <Page image={matches ? page18Mobile : page18} />
          <Page image={matches ? page19Mobile : page19} />
          <Page image={matches ? page20Mobile : page20} />
        </div>
        {!loading && (
          <>
            <div className="button-container">
              <button id="previous-page">&larr; Previous</button>
              <button id="previous-page-mobile">&larr; Previous</button>

              <button id="next-page">Next &rarr;</button>
            </div>
            <DownloadButton
              href="/PENHALIGONS_Xmas2021_GiftGuide.pdf"
              target="_blank"
            >
              Download Gift Guide
            </DownloadButton>
          </>
        )}
      </BookContainer>
    </>
  )
}
