import React from "react"
import { createGlobalStyle } from "styled-components"
import Header from "./components/Header"
import "./styles/reset.css"
import "./styles/oldGame.css"
import Footer from "./components/Footer"
import BookletDesktop from "./components/booklet/BookletDesktop"

const GlobalStyles = createGlobalStyle`
a {
  color: black;
  text-decoration: inherit;
}
`

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Header />
      <BookletDesktop />
      <Footer />
    </>
  )
}
